import axios, {AxiosPromise} from 'axios';

// @TODO: What does these types mean?
export enum QuestionType {
    empty,
    first,
    second
}

export interface GetQuestionsResponse {
    status: 'success';
    result: {
        group: string;
        type: QuestionType;
        questions: {
            id: number;
            title: string;
            answers: {
                [key: string]: string;
            };
        }[];
    }[];
}

export const getQuestions = (): AxiosPromise<GetQuestionsResponse> =>
    axios.get<GetQuestionsResponse>('library/questions');
