import {createSlice} from '@reduxjs/toolkit';
import {AppThunk} from '../../utils/store';
import {getDoctorEvent} from '../../utils/api/doctor';
import {getLatestClientEvent, getClientEvent} from '../../utils/api/client';
import {setLoading} from '../loading/loadingSlice';
import axios from 'axios';
import {fetchUser} from '../user/userSlice';

const initialState = {event: null};

const eventSlice = createSlice({
    name: 'event',
    initialState,
    reducers: {
        setEvent(state, {payload}) {
            state.event = payload;
        },
    }
});

export const {setEvent} = eventSlice.actions;
export default eventSlice.reducer;

export const fetchDoctorEvent = (
    event_id: any,
    skipLoading: boolean = false,
): AppThunk => async dispatch => {
    try {
        if (!skipLoading) {
            await dispatch(setLoading('fetching-event', true));
        }
        const res = await getDoctorEvent(event_id);

        if (res.data && res.data.result) {
            dispatch(setEvent(res.data.result));

            return res.data.result;
        }
    } finally {
        if (!skipLoading) {
            await dispatch(setLoading('fetching-event', false));
        }
    }
};

export const fetchClientEvent = (
    event_id: any,
    skipLoading: boolean = false,
): AppThunk => async dispatch => {
    try {
        if (!skipLoading) {
            await dispatch(setLoading('fetching-event', true));
        }

        const res = await getClientEvent(event_id);

        if (res.data && res.data.result) {
            dispatch(setEvent(res.data.result));
            return res.data.result;
        }
    } finally {
        if (!skipLoading) {
            await dispatch(setLoading('fetching-event', false));
        }
    }
};

export const clearEvent = () => dispatch => {
    dispatch(setEvent(null));
};

export const fetchLatestClientEvent = () => async dispatch => {
    return new Promise(async resolve => {
        const res = await getLatestClientEvent();

        if (res.data && res.data.result) {
            await dispatch(setEvent(res.data.result));
        }

        resolve();
    });
};

export const returnToEvent = (id, type) => async dispatch => {
    const {data} = await axios.put(`${type}/events/${id}/return`);

    if (data.status === 'success') {
        if (type === 'client') {
            dispatch(fetchLatestClientEvent());
        } else {
            dispatch(fetchDoctorEvent(id, true));
            dispatch(fetchUser());
        }
    }
}
