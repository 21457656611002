import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import {DoctorPayment, fetchDoctorPayments} from '../../utils/api/doctor';
import {AppThunk} from '../../utils/store';

const initialState = [];

const payments = createSlice({
    name: 'payments',
    reducers: {
        setPayments(state, {payload}: PayloadAction<DoctorPayment[]>) {
            return payload;
        }
    },
    initialState: initialState,
});

export default payments.reducer;

export const fetchPayments = (): AppThunk => async dispatch => {
    const response = await fetchDoctorPayments();

    if (response.data && response.data.result) {
        dispatch(payments.actions.setPayments(response.data.result));
    }
};

export const clearPayments = (): AppThunk => dispatch => {
    dispatch(payments.actions.setPayments(initialState));
};
