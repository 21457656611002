import {captureMessage, Severity} from '@sentry/browser';

export const resolveRedirectRoute = (user) => {
    let route;

    if (user.type === undefined) {
        captureMessage('User type is undefined', Severity.Fatal);

        return '/';
    }

    if (user.type === 'client') {
        if (!user.registration_completed) {
            route = '/registration/welcome';
        } else {
            route = '/client/session';
        }
    } else {
        if (user.next_event_id !== null) {
            route = `/therapist/session/${user.next_event_id}`;
        } else {
            route = '/therapist/calendar';
        }
    }

    return route;
};
