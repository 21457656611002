import React from 'react';
import {hydrate, render} from 'react-dom';
import 'core-js/es/promise/finally';
import './utils/i18n';
import App from './App';
import {init} from '@sentry/browser';
import {setupAxios} from './utils/api';
import * as serviceWorker from './serviceWorker';

import 'react-loader-spinner/dist/loader/css/react-spinner-loader.css';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import './styles/common/slider.scss';

setupAxios();

if (process.env.REACT_APP_SENTRY_ENV) {
    init({
        dsn: 'https://0930bd70054a49d191420737e27bb2ac@o382922.ingest.sentry.io/5212512',
        environment: process.env.REACT_APP_SENTRY_ENV,
        release: process.env.REACT_APP_SENTRY_RELEASE ? process.env.REACT_APP_SENTRY_RELEASE : null,
    });
}

const rootElement = document.getElementById('root');

if (rootElement.hasChildNodes()) {
    hydrate(<App />, rootElement);
} else {
    render(<App />, rootElement);
}

if (navigator.userAgent !== 'ReactSnap' && process.env.REACT_APP_SERVICE_WORKER === '1') {
    serviceWorker.register({onUpdate: registration => registration.waiting.postMessage({type: 'SKIP_WAITING'})});
}
