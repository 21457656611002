import React, {useState, useEffect} from 'react';
import {Alert} from 'react-bootstrap';
import {localStorageFallback} from '../../utils/storage';

interface AlertProps {
    display: boolean;
    storageName: string;
    children: React.ReactNode;
    dismissible?: boolean;
}

const GeneralAlert = (props: AlertProps) => {
    const {display, children, storageName, dismissible} = props;
    const [showAlert, setShowAlert] = useState(false);

    const handleDismissedAlert = () => {
        setShowAlert(false);
        localStorageFallback.setItem(storageName, 'true');
    };

    useEffect(() => {
        if (display && !localStorageFallback.getItem(storageName)) {
            setShowAlert(true);
        } else if (!display || localStorageFallback.getItem(storageName)) {
            setShowAlert(false);
        }
    }, [display, storageName]);

    return (
        <Alert
            show={showAlert}
            onClose={handleDismissedAlert}
            dismissible={dismissible}
            className='shadow bg-aqua-haze'
        >
            {children}
        </Alert>
    );
};

export default GeneralAlert;
