export const login = async () => {
    return new Promise((resolve, reject) => {
        if (window.FB) {
            FB.getLoginStatus(response => {
                if (response.status === 'connected') {
                    resolve(response.authResponse);
                } else {
                    FB.login(response => resolve(response.authResponse), {scope: 'email', return_scopes: true});
                }
            })
        } else {
            reject();
        }
    });
}

export const logout = async () => {
    return new Promise(resolve => {
        if (window.FB) {
            FB.getLoginStatus(response => {
                if (response.status === 'connected') {
                    FB.logout(response => {});
                }
            });
        }

        resolve();
    });
}
