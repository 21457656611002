import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import {getUserInfo, verifyLoginPhone} from '../../utils/api/auth';
import {AppThunk} from '../../utils/store';
import {setAuthHeader, removeAuthHeader} from '../../utils/api';
import {formatUserResponseToState} from './formatters';
import {
    updateClientInfo,
    ClientPayload
} from '../../utils/api/client';
import {
    setIsAuthenticated,
    setIsAuthenticating,
    setIsAuthenticatingWithToken,
    setExpiryLogout
} from '../authentication/authenticationSlice';
import {EventDoctor} from '../events/eventsSlice';
import {clearEvent} from '../events/eventSlice';
import {logout as facebookLogout} from '../../utils/facebook';
import {localStorageFallback, sessionStorageFallback} from '../../utils/storage';
import jwtDecode from 'jwt-decode';

export interface User {
    token?: string;
    id?: number;
    first_name?: string;
    last_name?: string;
    experience?: number;
    about?: string;
    education?: string;
    specialities?: string;
    phone?: string;
    email?: string;
    birthday?: string;
    gender?: string;
    photo?: string;
    therapy_experience?: boolean;
    specification?: {
        [key: string]: any;
    };
    registration_completed?: boolean;
    type?: 'client' | 'doctor';
    next_event_id?: any;
    assigned_card?: UserAssignedCard;
    doctor?: EventDoctor;
    pricing?: 'free' | 'paid';
    unconfirmed_event_id?: number;
    client_secret?: string;
    price?: string;
}

interface UserAssignedCard {
    id: number,
    last_digits?: number,
    card_brand?: string,
}

let initialState: User = {};

const userSlice = createSlice({
    name: 'user',
    initialState,
    reducers: {
        updateUser(state, {payload}: PayloadAction<User>) {
            state.id = payload.id;
            state.first_name = payload.first_name;
            state.last_name = payload.last_name;
            state.experience = payload.experience;
            state.about = payload.about;
            state.education = payload.education;
            state.specialities = payload.specialities;
            state.phone = payload.phone;
            state.email = payload.email;
            state.birthday = payload.birthday;
            state.gender = payload.gender;
            state.photo = payload.photo;
            state.therapy_experience = payload.therapy_experience;
            state.specification = payload.specification;
            state.registration_completed = payload.registration_completed;
            state.type = payload.type;
            state.next_event_id = payload.next_event_id;
            state.assigned_card = payload.assigned_card;
            state.doctor = payload.doctor;
            state.pricing = payload.pricing;
            state.unconfirmed_event_id = payload.unconfirmed_event_id;
            state.client_secret = payload.client_secret;
            state.price = payload.price;
        },
        setToken(state, {payload}: PayloadAction<{ token: string }>) {
            state.token = payload.token;
        },
        removeUser() {
            return initialState;
        },
        updateNextEventId(state, {payload}) {
            state.next_event_id = payload.next_event_id;
        }
    }
});

export const {updateUser, setToken, removeUser, updateNextEventId} = userSlice.actions;
export default userSlice.reducer;

export const fetchUser = (): AppThunk => async dispatch => {
    return new Promise(async (resolve, reject) => {
        try {
            const {data} = await getUserInfo();

            if (data && data.result) {
                dispatch(updateUser(formatUserResponseToState(data)));
                resolve(data.result);
            } else {
                dispatch(logout(true));
                resolve();
            }
        } catch (err) {
            dispatch(logout(true));
            reject();
        }
    })
};

export const updateClientData = (
    clientData: ClientPayload,
    withUserRefresh: boolean = false
): AppThunk<Promise<boolean>> => async (dispatch, getState) => {
    try {
        const {data} = await updateClientInfo(clientData);

        if (data && data.status === 'success') {
            if (withUserRefresh) {
                await dispatch(fetchUser());
            }

            return true;
        }

        return false;
    } catch (err) {
        return false;
    }
};

export interface LoginPayload {
    phone: string;
    code: string;
}

export const loginByPhone = (
    {
        phone,
        code
    }: LoginPayload): AppThunk<Promise<boolean>> => async dispatch => {
    try {
        dispatch(setIsAuthenticating(true));

        const {data} = await verifyLoginPhone({
            phone,
            code
        });

        if (data && data.status === 'success' && data.result) {
            await dispatch(login(data.result.jwt));

            return true;
        } else {
            dispatch(setIsAuthenticating(false));
        }
    } catch (e) {
        dispatch(setIsAuthenticating(false));
    }

    return false;
};

export const loginWithToken = (): AppThunk => async dispatch => {
    const token = localStorageFallback.getItem('token');

    if (token) {
        const decodedToken: {exp: number} = jwtDecode(token);

        if (decodedToken.exp) {
            const currentTimestamp = Date.now() / 1000;
            const expiryTimestamp = decodedToken.exp;

            if (expiryTimestamp - currentTimestamp < 5400) {
                dispatch(logout(true));
                return;
            }
        }

        try {
            dispatch(setIsAuthenticatingWithToken(true));
            setAuthHeader(token);
            await dispatch(setToken({token}));
            await dispatch(fetchUser());
            dispatch(setIsAuthenticated(true));
        } finally {
            dispatch(setIsAuthenticatingWithToken(false));
        }
    }
};

export const logout = (inactivityLogout = false): AppThunk => async dispatch => {
    localStorageFallback.removeItem('token');
    sessionStorageFallback.removeItem('newSessionClientData');
    sessionStorageFallback.removeItem('newSessionTherapyData');
    removeAuthHeader();
    dispatch(setIsAuthenticated(false));
    dispatch(setIsAuthenticating(false));
    dispatch(clearEvent());

    if (inactivityLogout) {
        dispatch(setExpiryLogout(true));
    }

    setTimeout(() => {
        facebookLogout();
        dispatch(removeUser());
    }, 300);
};

export const login = (token): AppThunk => async dispatch => {
    localStorageFallback.setItem('token', token);
    sessionStorageFallback.removeItem('newSessionClientData');
    setAuthHeader(token);
    dispatch(setToken({token}));
    dispatch(setIsAuthenticating(true));
    const userInfo = await dispatch(fetchUser());
    await dispatch(setIsAuthenticated(true));
    await dispatch(setIsAuthenticating(false));

    return userInfo;
};
