import format from 'date-fns/format';
import {formatToTimeZone} from 'date-fns-timezone';
import {enUS} from 'date-fns/locale';
import lt from './locales/lt';
import parse from 'date-fns/parse';
import getUnixTime from 'date-fns/getUnixTime';
import fromUnixTime from 'date-fns/fromUnixTime';
import formatDistanceToNow from 'date-fns/formatDistanceToNow';
import i18n from 'i18next';
import differenceInHours from 'date-fns/differenceInHours';
import differenceInMinutes from 'date-fns/differenceInMinutes';
import differenceInSeconds from 'date-fns/differenceInSeconds';

export const getShortDateFromTimestamp = (timestamp: number) =>
    format(getDateFromTimestamp(timestamp), 'MMMM d, cccc', {
        locale: i18n.language === 'lt' ? lt : enUS
    });

export const getCurrentTime = () => format(new Date(), 'HH:mm');

export const getCurrentTimestamp = () => getUnixTime(new Date());
export const getTimestampFromDateString = (dateTime: string) => getUnixTime(parse(dateTime, 'yyyy-MM-dd HH:mm:ss', new Date()));
export const getTimestampFromDate = (date: Date) => getUnixTime(date);
export const getDateFromTimestamp = (timestamp: number) => fromUnixTime(timestamp);
export const getDateStringFromTimestamp = (timestamp: number, timezone?: string, dateFormat: string = 'yyyy-MM-dd HH:mm:ss') => {
    if (timezone !== undefined) {
        return formatToTimeZone(getDateFromTimestamp(timestamp), 'YYYY-MM-DD HH:mm:ss', {
            timeZone: timezone
        });
    }

    return format(getDateFromTimestamp(timestamp), dateFormat);
};

export const getLongDateFromDate = (date) => format(date, 'EEEEEE, d MMMM, y HH:mm', {
    locale: i18n.language === 'lt' ? lt : enUS
});

export const getLongDateFromDateWithoutYear = (date) => format(date, 'EEEEEE, d, y HH:mm', {
    locale: i18n.language === 'lt' ? lt : enUS
});

export const getSuffixedDistanceToNow = (date: Date) => formatDistanceToNow(date, {
    locale: i18n.language === 'lt' ? lt : enUS,
    addSuffix: true,
});

export const getFormattedTimer = (date: Date) => {
    const currentDate = new Date();

    const hours = ('0' + differenceInHours(currentDate, date)).slice(-2);
    const minutes = ('0' + (differenceInMinutes(currentDate, date) % 60)).slice(-2);
    const seconds = ('0' + (differenceInSeconds(currentDate, date) % 60)).slice(-2);

    return `${hours}:${minutes}:${seconds}`;
};

export const getCountdownTimeInMinutesAndSeconds = (date: Date) => {
    const currentDate = new Date();

    const minutes = ('0' + (differenceInMinutes(date, currentDate) % 60)).slice(-2);
    const seconds = ('0' + (differenceInSeconds(date, currentDate) % 60)).slice(-2);

    return `${minutes}:${seconds}`;
};

export const formatToSimpleDateString = (date: Date) => format(date, 'yyyy-MM-dd');
export const formatToSlashedDateString = (date: Date) => format(date, 'yyyy/MM/dd');

export const yearsToHours = (years) => years * 1988;

export const getCurrentTimezone = () => Intl.DateTimeFormat().resolvedOptions().timeZone;
export const getUTCOffset = () => {
    let offset = (new Date()).getTimezoneOffset();
    const sign = offset <= 0 ? '+' : '-';
    offset = Math.abs(offset);

    const pad = number => (number < 10 ? '0' : '') + number;

    return `UTC${sign}${pad(offset / 60 | 0)}:${pad(offset % 60)}`;
}
