import React from 'react';

interface Props {
    opacity?: number;
    className?: string;
}

const FullPageLoader: React.FC<Props> = ({className= '', opacity = 1}) => (
    <div className={`fpl ${className}`} style={{opacity}}>
        <div className='fpr'>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
        </div>
    </div>
);

export default FullPageLoader;
