import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import {AppThunk} from '../../utils/store';
import {
    getEventsForCalendar,
} from '../../utils/api/doctor';
import format from 'date-fns/format';

interface State {
    events: [];
}

const initialState: State = {events: []};

const calendarSlice = createSlice({
    name: 'calendar',
    initialState,
    reducers: {
        setCalendarEvents(
            state,
            {
                payload
            }: PayloadAction<{
                events: [];
            }>
        ) {
            state.events = payload.events;
        }
    }
});

export const {setCalendarEvents} = calendarSlice.actions;

export default calendarSlice.reducer;

export const fetchEventsForCalendar = (month): AppThunk => async dispatch => {
    try {
        const {data} = await getEventsForCalendar(
            format(month, 'yyyy-LL')
        );

        if (data && data.result) {
            dispatch(setCalendarEvents({events: data.result}));
        }
    } catch (err) {
    }
};
