import {createSlice} from '@reduxjs/toolkit';
import {RootState} from '../index';

const loading = createSlice({
    name: 'loading',
    reducers: {
        setIsLoading(state, {payload}) {
            state[payload.key] = payload.value;
        }
    },
    initialState: {}
});

export default loading.reducer;

export const setLoading = (key: string, value: boolean) => {
    return loading.actions.setIsLoading({key, value});
};

export const isLoading = (state: RootState, key: string) => {
    return state.loading[key] !== undefined && state.loading[key];
};
