import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import {AppThunk} from '../../utils/store';
import {
    createEvent,
    getClientEvents,
    EventReasonCode,
    cancelClientEvent, completeClientEvent
} from '../../utils/api/client';
import {
    getDoctorEvents,
    cancelDoctorEvent,
    completeDoctorEvent,
    createDoctorEvent,
    updateDoctorEvent
} from '../../utils/api/doctor';
import {fetchClientEvent, fetchDoctorEvent} from './eventSlice';
import {setLoading} from '../loading/loadingSlice';

export interface Event {
    id: number;
    session_start_time: number;
    status: string;
    price: number;
    is_paid: boolean;
    reason: EventReasonCode;
    reason_message: string;
    client?: EventClient;
    doctor?: EventDoctor;
    client_secret?: string;
    payment_data?: EventPaymentData;
    discount?: EventDiscount;
}

export interface EventDiscount {
    code: string;
    price: string;
}

export interface EventPaymentData {
    last_digits?: number,
    card_brand?: string
}

export interface EventClient {
    first_name: string;
    birthday?: string;
    gender: string;
    photo: string;
    therapy_experience: boolean;
    specification: {
        [key: string]: any;
    };
}

export interface EventDoctor {
    first_name: string;
    last_name?: string;
    birthday: string;
    gender: string;
    photo: string;
    about: string;
    id: number;
}

interface State {
    client: Event[];
    doctor: Event[];
}

const initialState: State = {client: [], doctor: []};

const eventsSlice = createSlice({
    name: 'events',
    initialState,
    reducers: {
        addClientEvents(state, {payload}: PayloadAction<{ events: Event[] }>) {
            state.client = payload.events;
        },
        addDoctorEvents(state, {payload}: PayloadAction<{ events: Event[] }>) {
            state.doctor = payload.events;
        },
        clearEvents(state) {
            state.doctor = [];
        }
    }
});

export const {addClientEvents, addDoctorEvents, clearEvents} = eventsSlice.actions;
export default eventsSlice.reducer;

export const fetchEvents = (type: 'client' | 'doctor' = 'client', fetchAll: boolean = false): AppThunk => async dispatch => {
    dispatch(setLoading('events', true));

    let res;

    if (type === 'client') {
        res = await getClientEvents();
    } else {
        res = await getDoctorEvents(fetchAll);
    }

    if (res.data && res.data.result && type === 'client') {
        dispatch(addClientEvents({events: res.data.result}));
    }

    if (res.data && res.data.result && type === 'doctor') {
        dispatch(addDoctorEvents({events: res.data.result}));
    }

    dispatch(setLoading('events', false));
};

export interface CreateClientEventPayload {
    doctor_id: number;
    time_id: number;
    discount_code?: string;
    is_limited_offer?: boolean;
}

export const createClientEvent = (
    eventData: CreateClientEventPayload
): AppThunk<Promise<boolean>> => async () => {
    const {data} = await createEvent(eventData);

    return !!(data && data.result);
};

export const cancelEvent = (
    type: 'client' | 'doctor' = 'client',
    event_id: number
): AppThunk => async dispatch => {
    if (type === 'client') {
        await cancelClientEvent(event_id);
        await dispatch(fetchClientEvent(event_id));
    } else {
        await cancelDoctorEvent(event_id);
        await dispatch(fetchDoctorEvent(event_id));
    }
};

export const completeEvent = (
    type: 'client' | 'doctor' = 'client',
    event_id: number
): AppThunk => async dispatch => {
    let res;

    if (type === 'client') {
        res = await completeClientEvent(event_id);
    } else {
        res = await completeDoctorEvent(event_id);
    }

    if (res.data && res.data.result) {
        if (type === 'client') {
            dispatch(fetchClientEvent(event_id));
        } else {
            dispatch(fetchDoctorEvent(event_id));
        }
    }
};

export const createDoctorEventWithParent = (event_id: any, date: number): AppThunk<any> => async () => {
    try {
        return await createDoctorEvent(event_id, date);
    } catch (err) {
        return false;
    }
};

export const changeDoctorEventTime = (event_id: any, date: number): AppThunk<any> => async () => {
    try {
        return await updateDoctorEvent(event_id, date);
    } catch (err) {
        return false;
    }
};
