import axios, {AxiosPromise} from 'axios';

export interface GetMessagesResponse {
    status: 'success';
    result: {
        id: number;
        message: string;
        timestamp: number;
        user_id: number;
        is_doctor: boolean;
        is_client: boolean;
        event_id: number;
    }[];
}

export interface GetMessagesPayload {
    event_id?: number;
    chat_user_id?: number;
    limit?: number;
}

export const getMessages = (
    params: GetMessagesPayload
): AxiosPromise<GetMessagesResponse> =>
    axios.get<GetMessagesResponse>('messenger', {params});

export const getChatStatus = (params) => axios.get('messenger/status', {params});

export interface CreteMessageResponse {
    status: 'success';
    result: {
        event_id: number;
        message_id: number;
        message_text: string;
    };
}

export interface CreateMessagePayload {
    event_id?: number;
    chat_user_id?: number;
    message: string;
}

export const createMessage = (
    messagaData: CreateMessagePayload
): AxiosPromise<CreteMessageResponse> =>
    axios.post<CreteMessageResponse>('messenger', messagaData);
