import React from 'react';
import {useTranslation} from 'react-i18next';
import CookieBanner from './CookieBanner';

const CookieBannerWrap = () => {
    const {t} = useTranslation();

    return <CookieBanner
        bannerText={t('footer.cookieText')}
        necessaryOptionText={t('footer.necessaryCookieText')}
        acceptButtonText={t('footer.cookiePolicyAccept')}
        marketingOptionText={t('footer.statisticsCookieText')}
    />;
}

export default CookieBannerWrap;
