import {GetUserInfoResponse} from '../../utils/api/auth';
import {User} from './userSlice';

export const formatUserResponseToState = (
    {
        result
    }: GetUserInfoResponse): User => ({
    id: result.id,
    first_name: result.first_name,
    last_name: result.last_name,
    experience: result.experience,
    about: result.about,
    education: result.education,
    specialities: result.specialities,
    phone: result.phone,
    email: result.email,
    birthday: result.birthday,
    gender: result.gender,
    photo: result.photo,
    therapy_experience: result.therapy_experience,
    specification: result.specification,
    registration_completed: result.registration_completed,
    type: result.type,
    next_event_id: result.next_event_id,
    assigned_card: result.assigned_card,
    doctor: result.doctor,
    pricing: result.pricing,
    unconfirmed_event_id: result.unconfirmed_event_id,
    price: result.price,
});
