import {combineReducers} from 'redux';
import userReducer from './user/userSlice';
import doctorsReducer from './doctors/doctorsSlice';
import questionsReducer from './questions/questionsSlice';
import eventsReducer from './events/eventsSlice';
import eventReducer from './events/eventSlice';
import calendarReducer from './calendar/calendarSlice';
import timesReducer from './times/timesSlice';
import messagesReducer from './messages/messagesSlice';
import loadingReducer from './loading/loadingSlice';
import authenticationReducer from './authentication/authenticationSlice';
import paymentsReducer from './payments/paymentsSlice';

const rootReducer = combineReducers({
    calendar: calendarReducer,
    times: timesReducer,
    user: userReducer,
    doctors: doctorsReducer,
    events: eventsReducer,
    event: eventReducer,
    questions: questionsReducer,
    messages: messagesReducer,
    loading: loadingReducer,
    authentication: authenticationReducer,
    payments: paymentsReducer,
});

export type RootState = ReturnType<typeof rootReducer>;

export default rootReducer;
