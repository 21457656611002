import i18n from 'i18next';
import {initReactI18next} from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import store from './store';
import {loginWithToken} from '../features/user/userSlice';
import enTranslation from '../locales/en/translation.json';

i18n
    .use(LanguageDetector)
    .use(initReactI18next)
    .init({
        resources: {
            en: {
                translation: enTranslation,
            }
        },
        whitelist: ['en'],
        nonExplicitWhitelist: false,
        fallbackLng: 'en',
        interpolation: {
            escapeValue: false
        }
    }, async () => {
        try {
            await store.dispatch(loginWithToken());
        } catch {
        }
    });

export default i18n;
