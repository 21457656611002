import axios, {AxiosPromise} from 'axios';
import {UserGender} from './auth';
import {DoctorDateTime, DoctorsFilter} from '../../features/doctors/doctorsSlice';
import {getCurrentTimestamp} from '../dates';

export interface UpdateClientInfoResponse {
    status: 'success';
    result: 'updated';
}

export interface ClientPayload {
    first_name?: string;
    email?: string;
    therapy_experience?: boolean;
    gender?: string;
    specification?: {
        [key: string]: any;
    };
}

export const updateClientInfo = (
    clientInfo: ClientPayload
): AxiosPromise<UpdateClientInfoResponse> =>
    axios.put<UpdateClientInfoResponse>('client/info', clientInfo);

export interface SearchDoctorsDatesResponse {
    status: 'success';
    result: {
        doctor: {
            id: number;
            first_name: string;
            last_name: string;
            birthday?: string;
            about: string;
            photo: string;
            education: string;
            rating: number;
            specialities: string;
            experience: number;
            languages?: string[];
            reviews?: string[];
            gender: string;
            hours_of_therapy?: number;
            price: string;
        };
        dates: DoctorDateTime[];
    }[];
}

export const searchDoctorsDates = (
    params?: DoctorsFilter
): AxiosPromise<SearchDoctorsDatesResponse> =>
    axios.get<SearchDoctorsDatesResponse>('client/doctors/search', {params});

export enum EventReasonCode {
    empty,
    canceledByClient,
    canceledByDoctor
}

export interface GetClientEventsResponse {
    status: 'success';
    result: [
        {
            id: number;
            session_start_time: number;
            status: string;
            price: number;
            is_paid: boolean;
            reason: EventReasonCode;
            reason_message: string;
            doctor: {
                first_name: string;
                second_name: string;
                birthday?: string;
                gender: UserGender;
                photo: string;
                about: string;
                specification: {
                    [key: string]: any;
                };
                paid_link: string;
            };
        }
    ];
}

export const getClientEvents = (): AxiosPromise<GetClientEventsResponse> =>
    axios.get<GetClientEventsResponse>(`client/events?to=${getCurrentTimestamp()}`);

export interface CreateEventResponse {
    status: 'success';
    result: {
        event_id: number;
    };
}

export interface CreateEventPayload {
    doctor_id: number;
    time_id: number;
}

export const createEvent = (
    eventData: CreateEventPayload
): AxiosPromise<CreateEventResponse> =>
    axios.post<CreateEventResponse>('client/events', eventData);

export interface CancelClientEventResponse {
    status: 'success';
    result: 'event_canceled';
}

export const completeClientEvent = (
    event_id: number
) => axios.post(`client/events/complete/${event_id}`);


export const getClientEvent = (event_id: number) => axios.get(`client/events/${event_id}`);
export const getLatestClientEvent = () => axios.get(`client/events/latest`);

export const confirmEvent = (event_id: number, payment_method_id?: string) => axios.put(`client/events/${event_id}/confirm`, {
    payment_method_id
});

export const revokeCard = (card_id: number) => axios.delete(`me/cards/${card_id}`);

export const cancelClientEvent = async (eventId: number) => axios.delete(`client/events/${eventId}`);

export const acceptDiscount = async (eventId: number, code: string) => axios.post(`client/events/${eventId}/discount`, {
    code
});

export const verifyDiscountCode = async (code: string, therapistId: number) => axios.post('client/discounts/verify', {
    code,
    therapist_id: therapistId,
});

export const cancelDiscount = async (eventId: number) => axios.delete(`client/events/${eventId}/discount`);
