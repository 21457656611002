import axios, {AxiosPromise} from 'axios';
import {EventReasonCode} from './client';
import {getCurrentTimestamp, getTimestampFromDateString} from '../dates';
import parse from 'date-fns/parse';
import startOfMonth from 'date-fns/startOfMonth';
import lastDayOfMonth from 'date-fns/lastDayOfMonth';
import format from 'date-fns/format';

export const createConsultationTime = (
    date: string
): any =>
    axios.post('doctor/times', {
        time: getTimestampFromDateString(date),
    });

export const updateConsultationTime = (
    id: number,
    status: string
): any =>
    axios.put(`doctor/times/${id}`, {
        status,
    });

export interface GetCalendarResponse {
    status: 'success';
    result: {
        [key: number]: {
            id: number;
            time: number;
            status: string;
            event: {
                session_start_time: number;
                reason: EventReasonCode;
                reason_message: string;
                is_paid: boolean;
                price: number;
                status: string;
            };
            client: {
                id: number;
                first_name: string;
                last_name: string;
                photo: string;
            };
        };
    };
}

export const getTimesForDate = (date: string): AxiosPromise<GetCalendarResponse> => {
    const from = getTimestampFromDateString(`${date} 00:00:00`);
    const to = getTimestampFromDateString(`${date} 23:59:59`);

    return axios.get<GetCalendarResponse>(`doctor/times?from=${from}&to=${to}`);
};

export const getTimesForMonth = (month: any): AxiosPromise => {
    const from = getTimestampFromDateString(format(startOfMonth(month), 'yyyy-MM-dd HH:mm:ss'));
    const to = getTimestampFromDateString(format(lastDayOfMonth(month), 'yyyy-MM-dd 23:59:59'));

    return axios.get<GetCalendarResponse>(`doctor/times?from=${from}&to=${to}`);
};

export const getEventsForCalendar = (month: string): any => {
    const parsedMonth = parse(month, 'yyyy-MM', new Date());

    const from = getTimestampFromDateString(format(startOfMonth(parsedMonth), 'yyyy-MM-dd HH:mm:ss'));
    const to = getTimestampFromDateString(format(lastDayOfMonth(parsedMonth), 'yyyy-MM-dd 23:59:59'));

    return axios.get(`doctor/calendar?from=${from}&to=${to}`);
};

export interface GetDoctorEventsResponse {
    status: 'success';
    result: [
        {
            id: number;
            session_start_time: number;
            status: string;
            price: number;
            is_paid: boolean;
            reason: EventReasonCode;
            reason_message: string;
            client: {
                first_name: string;
                birthday: 'string';
                gender: string;
                photo: string;
                therapy_experience: boolean;
                specification: {
                    [key: string]: any;
                };
            };
        }
    ];
}

export const getDoctorEvents = (fetchAll: boolean = false): AxiosPromise<GetDoctorEventsResponse> =>
    axios.get<GetDoctorEventsResponse>(`doctor/events${!fetchAll ? ('?to=' + getCurrentTimestamp()) : ''}`);

export interface CancelDoctorEventResponse {
    status: 'success';
    result: 'event_canceled';
}

export const cancelDoctorEvent = (
    event_id: number
): AxiosPromise<CancelDoctorEventResponse> =>
    axios.post<CancelDoctorEventResponse>('doctor/events/cancel', {event_id});

export const completeDoctorEvent = (
    event_id: number
) => axios.post(`doctor/events/complete/${event_id}`);

export const getDoctorEvent = (event_id: any): AxiosPromise =>
    axios.get(`doctor/events/${event_id}`);

export const createDoctorEvent = (
    event_id: number,
    date: any
) => axios.post(`doctor/events`, {
    event_id,
    date
});

export const updateDoctorEvent = (
    event_id: number,
    date: any
) => axios.put(`doctor/events/${event_id}`, {
    date
});

export const updateDefaultPrice = (price: string) => axios.put(`doctor/default-price`, {
    price
});

export interface DoctorPayment {
    client: {
        first_name: string,
        last_name?: string,
    },
    price: number,
    paid_at: number,
    session_start_time: number;
}

export interface FetchDoctorPaymentsResponse {
    status: 'success',
    result: DoctorPayment[],
}

export const fetchDoctorPayments = (): AxiosPromise<FetchDoctorPaymentsResponse> =>
    axios.get('doctor/payments');

export const addBatchTimes = times => axios.post('doctor/times/batch', {times});

export const fetchTherapists = (language, limit) => axios.get('public/therapists', {params: {limit, language}});
export const fetchTherapistBySlug = (slug, language) => axios.get(`public/therapists/${slug}?language=${language}`);
