import React from 'react';

import './TrustPilotButton.scss';

interface Props {
    text: string;
    variant: 'dark' | 'light';
    large?: boolean;
    className?: string;
}

const TrustPilotButton = ({text, variant, className = '', large = false}: Props) => {
    return <div className={`TrustPilotButton ${className}`}>
        <a className={`btn rounded ${variant}${large ? ' btn-lg' : ' btn-default'}`}
           href='https://www.trustpilot.com/review/tokitus.com' target='_blank'
           rel='noopener noreferrer'>
            <div>
                <span>{text}</span>
                <img width='70' height='20' loading='lazy' src={`/images/landing/trustpilot-${variant}.svg`} alt='Trustpilot logo'/>
            </div>
        </a>
    </div>;
};

export default TrustPilotButton;
