import axios from 'axios';
import store from '../store';
import {logout} from '../../features/user/userSlice';
import {setContext} from '@sentry/browser';

export const setAuthHeader = (token: string) => {
    axios.defaults.headers.common = {
        Authorization: `Bearer ${token}`
    };
};

export const removeAuthHeader = () => {
    axios.defaults.headers.common = {};
};

export const setupAxios = () => {
    axios.defaults.baseURL = process.env.REACT_APP_API_URL;

    axios.interceptors.response.use(response => response, error => {
        setContext('error', error);

        if (error.response && error.response.status === 401) {
            store.dispatch(logout());
            return Promise.reject();
        }

        return Promise.reject(error);
    })
};
