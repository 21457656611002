import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import {AppThunk} from '../../utils/store';
import {getMessages, createMessage, getChatStatus} from '../../utils/api/messenger';

export interface Message {
    id: number;
    message: string;
    timestamp: number;
    user_id: number;
    is_doctor: boolean;
    is_client: boolean;
    seen_at?: number;
}

type State = {
    list: Message[];
    status: {};
};

const initialState: State = {
    list: [],
    status: {},
};

const messagesSlice = createSlice({
    name: 'messages',
    initialState,
    reducers: {
        addMessages(state, {payload}: PayloadAction<{ messages: Message[] }>) {
            state.list = payload.messages;
        },
        setChatStatus(state, {payload}) {
            state.status = payload.status;
        }
    }
});

export const {addMessages, setChatStatus} = messagesSlice.actions;
export default messagesSlice.reducer;

export interface FetchMessagesPayload {
    event_id?: number;
    chat_user_id?: number;
    limit?: number;
}

export const fetchChatStatusByEvent = (event_id): AppThunk => async dispatch => {
    const {data} = await getChatStatus({event_id});

    if (data && data.result && data.status === 'success') {
        dispatch(setChatStatus({status: data.result}));
    }
};

export const fetchChatStatusByChatUser = (chat_user_id: number): AppThunk => async dispatch => {
    const {data} = await getChatStatus({chat_user_id});

    if (data && data.result && data.status === 'success') {
        dispatch(setChatStatus({status: data.result}));
    }
};

export const fetchMessages = (
    payload: FetchMessagesPayload
): AppThunk => async dispatch => {
    try {
        const {data} = await getMessages(payload);

        if (data && data.result && data.status === 'success') {
            dispatch(addMessages({messages: data.result}));
        }
    } catch (err) {
    }
};

export interface SendMessagePayload {
    event_id?: number;
    chat_user_id?: number;
    message: string;
}

export const sendMessage = (
    {
        event_id,
        chat_user_id,
        message
    }: SendMessagePayload): AppThunk => async () => {
    try {
        await createMessage({event_id, message, chat_user_id});
    } catch (err) {
    }
};
