import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import {AppThunk} from '../../utils/store';
import {searchDoctorsDates} from '../../utils/api/client';

export interface Doctor {
    id?: number;
    first_name?: string;
    last_name?: string;
    birthday?: string;
    about?: string;
    photo?: string;
    dates?: DoctorDateTime[];
    experience: number;
    rating: number;
    languages?: string[];
    reviews?: string[];
    specialities?: string;
    education?: string;
    gender: string;
    price: string;
}

export interface DoctorDateTime {
    session_start_time: number,
    id: number,
}

let initialState: Doctor[] = [];

const doctorsSlice = createSlice({
    name: 'doctors',
    initialState,
    reducers: {
        addDoctors(state, {payload}: PayloadAction<{ doctors: Doctor[] }>) {
            return payload.doctors;
        },
        clearDoctors() {
            return initialState;
        }
    }
});

export const {addDoctors, clearDoctors} = doctorsSlice.actions;
export default doctorsSlice.reducer;

export interface DoctorsFilter {
    doctor_id?: number;
    limit?: number;
    languages?: string[];
    gender?: string;
    language?: string;
    therapist_id?: string;
    single?: number;
    topic?: string;
}

export const fetchDoctors = (filter: DoctorsFilter = {}): AppThunk => async dispatch => {
    return new Promise(async (accept, reject) => {
        try {
            const {data} = await searchDoctorsDates(filter);

            if (data && data.result) {
                dispatch(
                    addDoctors({
                        doctors: data.result.map(({doctor, dates}) => ({
                            id: doctor.id,
                            first_name: doctor.first_name,
                            last_name: doctor.last_name,
                            birthday: doctor.birthday,
                            about: doctor.about,
                            photo: doctor.photo,
                            experience: doctor.experience,
                            rating: doctor.rating,
                            education: doctor.education,
                            specialities: doctor.specialities,
                            languages: doctor.languages,
                            gender: doctor.gender,
                            price: doctor.price,
                            dates
                        }))
                    })
                );
            }

            accept();
        } catch (err) {
            reject();
        }
    });
};
