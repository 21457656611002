import React, {useCallback} from 'react';
import {Link} from 'react-router-dom';
import {User} from '../../features/user/userSlice';
import {useTranslation} from 'react-i18next';
import {useHistory} from 'react-router';
import cx from 'classnames';

import './MobileMenu.scss';

interface Props {
    user: User;
    mainMenu: {
        title: string;
        href: string;
        external?: boolean;
    }[];
    paidClientAuthenticatedMenu: {
        title: string;
        href: string;
    }[];
    therapistAuthenticatedMenu: {
        title: string;
        href: string;
    }[];
    closeMenu: () => void;
    onLogout: () => void;
    currentPath: string;
}

const MobileMenu = (
    {
        user,
        mainMenu,
        closeMenu,
        paidClientAuthenticatedMenu,
        therapistAuthenticatedMenu,
        onLogout,
        currentPath,
    }: Props) => {
    const {t} = useTranslation();
    const {push} = useHistory();

    const signedIn = Boolean(user.token);

    const onLogoutClicked = () => {
        closeMenu();
        onLogout();
    };

    const renderFullName = useCallback(() => {
        const {first_name, last_name} = user;

        let fullName = first_name;

        if (last_name !== null) {
            fullName += ` ${last_name}`;
        }

        return fullName;
    }, [user]);

    const renderMenuHeader = useCallback(() => (
        <div className='menuHeader'>
            {
                signedIn && user.first_name
                    ? <div className='nameWrap'>
                        <div className='dot'/>
                        <div className='fullName'>{renderFullName()}</div>
                    </div>
                    : (
                        <Link to='/' className='mr-3' onClick={closeMenu}>
                            <img
                                srcSet='/images/tokitus-logo-v2-2x.png 2x'
                                src='/images/tokitus-logo-v2.png'
                                alt='Tokitus Logo'
                                width='142'
                                height='32'
                            />
                        </Link>
                    )
            }

            <div className='closeButton' onClick={closeMenu}>&times;</div>
        </div>
    ), [user.first_name, closeMenu, signedIn, renderFullName]);

    const renderMenuItem = useCallback(menuItem => {
        if (menuItem.hide) {
            return null;
        }

        const {href, title} = menuItem;

        return <div key={href}>
            <Link
                to={href}
                onClick={closeMenu}
                className={cx('text-shark nav-link fs-20', {
                    'fw-700 menu-item-underline': currentPath === href,
                })}
            >
                {title}
            </Link>
        </div>;
    }, [closeMenu, currentPath]);

    const renderMenu = useCallback((title, href, external) => {
        if (external) {
            return <a target='_blank' rel='noopener noreferrer' key={href} className='nav-link text-shark fs-20' href={href}>{title}</a>
        }

        return (
            <Link key={href} to={href} className={cx('nav-link text-shark fs-20', {
                'fw-700 menu-item-underline': currentPath === href,
            })} onClick={closeMenu}>
                {title}
            </Link>
        );
    }, [closeMenu, currentPath]);

    const renderMainMenu = useCallback(() => (
        <div className='mainMenu'>
            {
                mainMenu.map(({title, href, external}) => renderMenu(title, href, external))
            }
        </div>
    ), [renderMenu, mainMenu]);

    const renderClientAuthenticatedMenu = useCallback(() => {
        if (user.pricing === 'paid' && user.registration_completed) {
            return paidClientAuthenticatedMenu.map(menuItem => renderMenuItem(menuItem));
        }
    }, [user.registration_completed, user.pricing, paidClientAuthenticatedMenu, renderMenuItem]);

    const renderTherapistAuthenticatedMenu = useCallback(() => {
        return therapistAuthenticatedMenu.map(menuItem => renderMenuItem(menuItem));
    }, [therapistAuthenticatedMenu, renderMenuItem]);

    const goToLogin = () => {
        closeMenu();
        push('/login');
    };

    const renderAuthenticationButtons = () => {
        return <div className='authenticationButtons'>
            <Link to='/registration/welcome' onClick={closeMenu}>
                <button className='btn btn-lg btn-primary signUpButton w-100 rounded mb-3'>
                    {t('landing.begin')}
                </button>
            </Link>
            <button className='btn btn-lg btn-outline-mako logInButton w-100 rounded' onClick={goToLogin}>
                {t('navbar.logIn')}
            </button>
        </div>;
    };

    const renderLogout = useCallback(() => (
        <Link
            to='/'
            onClick={onLogoutClicked}
            className='text-shark nav-link'
        >
            {t('navbar.logout')}
        </Link>
        // eslint-disable-next-line
    ), [onLogoutClicked]);

    const renderAuthenticatedMenu = useCallback(() => {
        let authenticatedMenu = null;

        if (user.type === 'client') {
            authenticatedMenu = renderClientAuthenticatedMenu();
        } else {
            authenticatedMenu = renderTherapistAuthenticatedMenu();
        }

        return <>
            <div className='authenticatedMenu'>
                {authenticatedMenu}
                {renderLogout()}
            </div>
            <div className='menuBorder'/>
        </>;

    }, [user.type, renderClientAuthenticatedMenu, renderTherapistAuthenticatedMenu, renderLogout]);

    return <div className='mobileMenu'>
        {renderMenuHeader()}
        <div className={`menuBody ${signedIn ? '' : 'unauthenticated'}`}>
            {
                signedIn && renderAuthenticatedMenu()
            }
            {renderMainMenu()}
        </div>
        <div className='menuFooter'>
            {
                !signedIn && renderAuthenticationButtons()
            }
            <div className='rightsText'>
                © 2020-2021 {t('footer.allRightsReserved')}.
            </div>
        </div>
    </div>;
};

export default MobileMenu;
