import React, {useEffect, useState} from 'react';
import Cookies from 'js-cookie';
import {Helmet} from 'react-helmet';

import './CookieBanner.scss';

interface Props {
    onAcceptMarketing?: () => void,
    bannerText: string,
    necessaryOptionText: string,
    acceptButtonText: string,
    marketingOptionText: string,
    manageSettingsText?: string,
}

const CookieBanner = (props: Props) => {
    const cookie = Cookies.get('cookie_consent');
    const [isAccepted, setIsAccepted] = useState(cookie !== undefined);

    const [settings] = useState({
        necessary: true,
        marketing: true,
    });

    useEffect(() => {
        if (cookie !== undefined) {
            if (cookie !== 'true') {
                Cookies.set('cookie_consent', true, {expires: 365});
            }

            accept(true);
        }
        //eslint-disable-next-line
    }, []);

    const accept = (acceptedSettings) => {
        setIsAccepted(true);

        // if (acceptedSettings.marketing && props.onAcceptMarketing !== undefined) {
        //     props.onAcceptMarketing();
        // }
    };

    if (isAccepted) {
        return (
            <Helmet>
                <script>
                    {`(function(w,d,s,l,i){w[l] = w[l] || [];w[l].push({'gtm.start':
                    new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
                    j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
                    'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
                })(window,document,'script','dataLayer','${process.env.REACT_APP_GTM_ID}');
                `}
                </script>
            </Helmet>
        );
    }

    const onAcceptClick = () => {
        Cookies.set('cookie_consent', true, {expires: 365});
        accept(settings);
    };

    return (
        <>
            <div className='d-flex cookieBanner flex-column container'>
                <div dangerouslySetInnerHTML={{__html: props.bannerText}}/>
                <div className='w-100 text-right mt-1'>
                    <button
                        className='btn btn-outline-flint acceptButton rounded'
                        onClick={onAcceptClick}
                    >
                        {props.acceptButtonText}
                    </button>
                </div>
            </div>
        </>
    );
};

export default CookieBanner;
