import axios, {AxiosPromise} from 'axios';
import {EventDoctor} from '../../features/events/eventsSlice';
import i18n from 'i18next';
import {getCurrentTimezone} from '../dates';

export interface AuthLoginResponse {
    status: 'success' | 'error';
    result: 'phone_verification_required';

}

export const authLogin = (params: object): AxiosPromise =>
    axios.post<AuthLoginResponse>('auth/login', params, {
        headers: {
            'User-Language': i18n.language,
            'Time-Zone': getCurrentTimezone(),
        }
    });

export const authRegister = (params: object): AxiosPromise =>
    axios.post<AuthLoginResponse>('auth/register', params, {
        headers: {
            'User-Language': i18n.language,
            'Time-Zone': getCurrentTimezone(),
        }
    });

export interface VerifyPhoneResponse {
    status: 'success' | 'error';
    result: {
        jwt: string;
    };
}

export interface VerifyPhonePayload {
    phone: string;
    code: string;
}

export const verifyLoginPhone = (
    verifyData: VerifyPhonePayload
): AxiosPromise<VerifyPhoneResponse> =>
    axios.post<VerifyPhoneResponse>('auth/login/verify', verifyData, {
        headers: {
            'Time-Zone': getCurrentTimezone(),
            'User-Language': i18n.language,
        }
    });

export type UserType = 'client' | 'doctor';
export type UserGender = 'male' | 'female';

export interface GetUserInfoResponse {
    status: 'success';
    result: {
        id: number;
        first_name: string;
        last_name: string;
        experience: number;
        about: string;
        education: string;
        specialities: string;
        phone: string;
        email: string;
        birthday: string;
        gender: string;
        photo: string;
        therapy_experience: boolean;
        specification: {
            [key: string]: any;
        };
        registration_completed: boolean;
        type: UserType;
        next_event_id: any;
        assigned_card: any;
        doctor?: EventDoctor;
        pricing: 'free' | 'paid';
        unconfirmed_event_id?: number;
        reviews: any;
        price?: string;
    };
}

export const getUserInfo = (): AxiosPromise<GetUserInfoResponse> => axios.get<GetUserInfoResponse>('me', {
    params: {
        language: i18n.language ?? 'en',
    }
});

export const initiateGoogleAuth = (params): AxiosPromise => axios.post('authentication/google', params);
