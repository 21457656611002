import {createSlice} from '@reduxjs/toolkit';

const authentication = createSlice({
    name: 'authentication',
    reducers: {
        setIsAuthenticated(state, {payload}) {
            state.isAuthenticated = payload;
        },
        setIsAuthenticating(state, {payload}) {
            state.isAuthenticating = payload;
        },
        setIsAuthenticatingWithToken(state, {payload}) {
            state.isAuthenticatingWithToken = payload;
        },
        setExpiryLogout(state, {payload}) {
            state.isExpiryLogout = payload;
        }
    },
    initialState: {
        isAuthenticated: false,
        isAuthenticating: false,
        isAuthenticatingWithToken: false,
        isExpiryLogout: false,
    }
});


export default authentication.reducer;
export const {setExpiryLogout} = authentication.actions;

export const setIsAuthenticated = (value: boolean) => {
    return authentication.actions.setIsAuthenticated(value);
};

export const setIsAuthenticating = (value: boolean) => {
    return authentication.actions.setIsAuthenticating(value);
};

export const setIsAuthenticatingWithToken = (value: boolean) => {
    return authentication.actions.setIsAuthenticatingWithToken(value);
};
