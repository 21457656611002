import {hot} from 'react-hot-loader/root';
import React, {useEffect, Suspense, lazy} from 'react';
import {BrowserRouter as Router, Route, Switch, useLocation} from 'react-router-dom';
import Footer from './Footer';
import {useSelector} from 'react-redux';
import {RootState} from '../features';
import NavBar from './NavBar';
import CookieBannerWrap from './CookieBannerWrap';
import {cookieEnabled, sessionStorageFallback} from '../utils/storage';
import FullPageLoader from './ui/FullPageLoader';
import {setContext} from '@sentry/browser';
import {connectedRouterRedirect} from 'redux-auth-wrapper/history4/redirect';
import locationHelperBuilder from 'redux-auth-wrapper/history4/locationHelper';
import {resolveRedirectRoute} from '../utils/redirect';

export const CLIENT_QUIZ_RESULTS_URL = '/client/quiz-results';

const lazyWithRetry = (fn, retries = 3, retryAfterMs = 400): Promise<{ default: React.ComponentType<any> }> =>
    new Promise((resolve, reject) => {
        fn()
            .then(resolve)
            .catch((error) => {
                setContext('errorData', error);

                setTimeout(() => {
                    if (retries === 1) {
                        reject(error);
                        return;
                    }
                    lazyWithRetry(fn, retries - 1, retryAfterMs).then(resolve, reject);
                }, retryAfterMs);
            });
    });

const ScrollToTop = () => {
    const {pathname} = useLocation();

    useEffect(() => {
        document.body.scrollTop = 0;
        document.documentElement.scrollTop = 0;
    }, [pathname]);

    return null;
};

const therapistIsAuthenticated = connectedRouterRedirect({
    redirectPath: (state: RootState) => {
        if (state.authentication.isAuthenticated && state.user.type !== 'doctor') {
            return '/';
        }

        return '/login';
    },
    authenticatedSelector: (state: RootState) => state.user && state.authentication.isAuthenticated && state.user.type === 'doctor',
    wrapperDisplayName: 'AuthenticatedTherapist',
});

const clientIsAuthenticated = connectedRouterRedirect({
    redirectPath: (state: RootState) => {
        if (state.authentication.isAuthenticated && state.user.type !== 'client') {
            return '/';
        }

        return '/login';
    },
    authenticatedSelector: (state: RootState, props: any) => {
        const {user} = state;
        const {location} = props;

        if (location.pathname === CLIENT_QUIZ_RESULTS_URL) {
            return user && state.authentication.isAuthenticated && user.type === 'client';
        }

        return user && state.authentication.isAuthenticated && user.type === 'client' && user.registration_completed;
    },
    wrapperDisplayName: 'AuthenticatedClient',
});

const locationHelper = locationHelperBuilder({});

const userIsNotAuthenticated = connectedRouterRedirect({
    redirectPath: (state: RootState, ownProps) => {
        if (!state.authentication.isAuthenticated) {
            return null;
        }

        const {user} = state;

        if (user.type === 'client') {
            if (!user.registration_completed) {
                if (user.specification !== null) {
                    return CLIENT_QUIZ_RESULTS_URL;
                }

                return '/registration/welcome';
            }

            const redirectPath = sessionStorageFallback.getItem('redirect-path');

            if (redirectPath) {
                return redirectPath;
            }
        }

        return locationHelper.getRedirectQueryParam(ownProps) || resolveRedirectRoute(user);
    },
    allowRedirectBack: false,
    authenticatedSelector: (state: RootState) => !state.authentication.isAuthenticated,
    wrapperDisplayName: 'NotAuthenticatedUser',
});

const AuthReturnPage = lazy(() => lazyWithRetry(() => import('./pages/AuthReturnPage')));
const FullSizeRegistration = lazy(() => lazyWithRetry(() => import('./pages/registration/v3/FullSizeRegistration')));
const TherapistsPage = lazy(() => lazyWithRetry(() => import('./pages/TherapistsPage')));
const TherapistPage = lazy(() => lazyWithRetry(() => import('./pages/TherapistPage')));
const ContactPage = lazy(() => lazyWithRetry(() => import('./pages/ContactPage')));
const FaqPage = lazy(() => lazyWithRetry(() => import('./pages/FaqPage')));
const AboutPage = lazy(() => lazyWithRetry(() => import('./pages/AboutPage')));
const BlogPage = lazy(() => lazyWithRetry(() => import('./pages/BlogPage')));
const BlogPost = lazy(() => lazyWithRetry(() => import('./pages/BlogPost')));
const LogInPage = lazy(() => lazyWithRetry(() => import('./pages/LogInPage')));
const PasswordRecoveryPage = lazy(() => lazyWithRetry(() => import('./pages/PasswordRecoveryPage')));
const ClientRoutes = lazy(() => lazyWithRetry(() => import('./ClientRoutes')));
const TherapistRoutes = lazy(() => lazyWithRetry(() => import('./TherapistRoutes')));
const LandingPage = lazy(() => lazyWithRetry(() => import('./pages/LandingPage')));

const UnauthenticatedLogInPage = userIsNotAuthenticated(() => <LogInPage/>);
const AuthenticatedTherapistRoutes = therapistIsAuthenticated(() => <TherapistRoutes/>)
const AuthenticatedClientRoutes = clientIsAuthenticated(() => <ClientRoutes/>)

const Content = () => {
    const {isAuthenticatingWithToken} = useSelector((state: RootState) => state.authentication);

    const renderRouter = () => (
        <>
            <NavBar/>
            <Suspense fallback={<FullPageLoader opacity={0.7}/>}>
                <ScrollToTop/>
                <Switch>
                    <Route path='/therapists' exact>
                        <TherapistsPage/>
                    </Route>
                    <Route path='/therapists/:slug'>
                        <TherapistPage/>
                    </Route>
                    <Route path='/contact'>
                        <ContactPage/>
                    </Route>
                    <Route path='/faq'>
                        <FaqPage/>
                    </Route>
                    <Route path='/about'>
                        <AboutPage/>
                    </Route>
                    <Route exact path='/blog'>
                        <BlogPage/>
                    </Route>
                    <Route path='/blog/:slug'>
                        <BlogPost/>
                    </Route>
                    <Route path='/login' component={UnauthenticatedLogInPage}/>
                    <Route path='/password-recovery'>
                        <PasswordRecoveryPage/>
                    </Route>
                    <Route path='/client' component={AuthenticatedClientRoutes}/>
                    <Route path='/therapist' component={AuthenticatedTherapistRoutes}/>
                    <Route path='/'>
                        <LandingPage/>
                    </Route>
                </Switch>
                <Footer/>
            </Suspense>
        </>
    );

    if (isAuthenticatingWithToken) {
        return <FullPageLoader/>;
    }

    return <Router>
        <ScrollToTop/>
        {navigator.userAgent !== 'ReactSnap' && cookieEnabled && <CookieBannerWrap/>}
        <Switch>
            <Route path='/auth-return'>
                <AuthReturnPage/>
            </Route>
            <Route path='/registration' exact>
                <FullSizeRegistration/>
            </Route>
            <Route path='/registration/:step'>
                <FullSizeRegistration/>
            </Route>
            {renderRouter()}
        </Switch>
    </Router>;
};

export default process.env.NODE_ENV === 'development' ? hot(Content) : Content;
