import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import {AppThunk} from '../../utils/store';
import {getQuestions, QuestionType} from '../../utils/api/library';

export interface Question {
    group: string;
    type: QuestionType;
    questions: {
        id: number;
        title: string;
        answers: {
            [key: string]: string;
        };
    }[];
}

let initialState: Question[] = [];

const questionsSlice = createSlice({
    name: 'questions',
    initialState,
    reducers: {
        addQuestions(state, {payload}: PayloadAction<{ questions: Question[] }>) {
            state.push(...payload.questions);
        }
    }
});

export const {addQuestions} = questionsSlice.actions;
export default questionsSlice.reducer;

export const fetchQuestions = (): AppThunk => async dispatch => {
    try {
        const {data} = await getQuestions();

        if (data && data.result) {
            dispatch(addQuestions({questions: data.result}));
        }
    } catch (err) {
    }
};
