type StorageFallback = Pick<Storage, 'setItem' | 'getItem' | 'removeItem'> & { storage: { [key: string]: string } };

const fallbackStorage: StorageFallback = {
    storage: {},
    setItem(key: string, value: string) {
        this.storage[key] = value;
    },
    getItem(key: string): string | null {
        return this.storage[key] ?? null;
    },
    removeItem(key: string) {
        delete this.storage[key];
    }
}

export const cookieEnabled = navigator.cookieEnabled;
export const sessionStorageFallback: Storage | StorageFallback = cookieEnabled ? window.sessionStorage : fallbackStorage;
export const localStorageFallback: Storage | StorageFallback = cookieEnabled ? window.localStorage : fallbackStorage;
