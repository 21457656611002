import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import {AppThunk} from '../../utils/store';
import {
    getTimesForDate,
    createConsultationTime,
    updateConsultationTime
} from '../../utils/api/doctor';
import {EventReasonCode} from '../../utils/api/client';
import {setLoading} from '../loading/loadingSlice';

export interface CalendarTime {
    id: number;
    time: number;
    status: string;
    event: {
        session_start_time: number;
        reason: EventReasonCode;
        reason_message: string;
        is_paid: boolean;
        price: number;
        status: string;
    };
    client: {
        id: number;
        first_name: string;
        last_name: string;
        photo: string;
    };
}

interface State {
    times: {
        [key: number]: CalendarTime;
    };
}

const initialState: State = {times: {}};

const timesSlice = createSlice({
    name: 'calendar',
    initialState,
    reducers: {
        setCalendarTimes(
            state,
            {
                payload
            }: PayloadAction<{
                times: {
                    [key: number]: CalendarTime;
                };
            }>
        ) {
            state.times = payload.times;
        },
        removeCalendarTimes(
            state,
            {payload}: PayloadAction<{ times: string[] }>
        ) {
            payload.times.forEach((time, id) => {
                if (state.times[id] !== undefined) {
                    delete state.times[id];
                }
            });
        }
    }
});

export const {setCalendarTimes} = timesSlice.actions;

export default timesSlice.reducer;

export const fetchDoctorCalendarTimes = (date: string): AppThunk => async dispatch => {
    dispatch(setLoading('doctorCalendarTimes', true));

    try {
        const {data} = await getTimesForDate(date);

        if (data && data.result) {
            dispatch(setCalendarTimes({times: data.result}));
        }
    } catch (err) {
    } finally {
        dispatch(setLoading('doctorCalendarTimes', false));
    }
};

export const onCreateConsultationTime = (
    date: string
): AppThunk<Promise<boolean>> => async () => {
    try {
        const {data} = await createConsultationTime(date);

        return data && data.result && data.result === 'created';
    } catch (err) {
        return false;
    }
};

export const onUpdateConsultationStatus = (
    id: number,
    status: string
): AppThunk<Promise<boolean>> => async () => {
    try {
        await updateConsultationTime(id, status);
    } catch (err) {
        return false;
    }
};
