import React, {Suspense} from 'react';
import './styles/index.scss';
import {Provider} from 'react-redux';
import store from './utils/store';
import Content from './components/Content';
import {Helmet} from 'react-helmet';
import FullPageLoader from './components/ui/FullPageLoader';

const App: React.FC = () => {
    return (
        <Suspense fallback={<FullPageLoader/>}>
            <Provider store={store}>
                <Helmet
                  defaultTitle='Professional Therapy Online For An Affordable Price | Tokitus'
                >
                    <meta
                        name='description'
                        content='Online therapy platform. We offer online counseling with professional psychologists and therapists for an affordable price and in a variety of languages.'
                    />
                    <link rel='canonical' href='https://tokitus.com'/>
                    <meta property='og:url' content='https://tokitus.com'/>
                    <meta property='fb:app_id' content='346703592990925'/>
                    <meta property='og:type' content='website'/>
                    <meta property='og:site_name' content='Tokitus Online Therapy'/>
                    <meta property='og:title' content='Professional Therapy Online For An Affordable Price | Tokitus'/>
                    <meta property='og:description' content='Online therapy platform. We offer online counseling with professional psychologists and therapists for an affordable price and in a variety of languages.'/>
                    <meta property='og:image' content='https://tokitus.com/images/social-image-ajkqwe.jpg'/>
                </Helmet>
                <Content/>
            </Provider>
        </Suspense>
    );
};

export default App;
