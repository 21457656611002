import React from 'react';
import {useTranslation} from 'react-i18next';
import {Link} from 'react-router-dom';
import TrustPilotButton from './pages/LandingPage/Reviews/TrustPilotButton';

import './Footer.scss';

const Footer = () => {
    const {t} = useTranslation();

    const menu = [
        {
            label: t('navbar.faq'),
            href: '/faq',
        },
        {
            label: t('navbar.about'),
            href: '/about',
        },
        {
            label: t('navbar.business'),
            href: '/business',
        },
        {
            label: t('navbar.therapists'),
            href: '/therapists',
        },
        {
            label: t('navbar.contact'),
            href: '/contact',
        },
        {
            label: t('navbar.blog'),
            href: '/blog',
        },
    ];

    return (
        <footer className='w-100 pt-5 pb-3 bg-shark mt-auto Footer'>
            <div className='container'>
                <div className='row'>
                    <div className='col-12 col-lg-4 text-white'>
                        <div className='row'>
                            {
                                menu.map(
                                    ({label, href}) => (
                                        <div
                                            key={label}
                                            className='col-6 col-lg-5 d-flex justify-content-center justify-content-lg-start mb-2'
                                        >
                                            <div className='link-item'>
                                                <Link to={href} className='fs-14 text-regent-gray text-decoration-none'>
                                                    {label}
                                                </Link>
                                            </div>
                                        </div>
                                    ),
                                )
                            }
                        </div>
                    </div>
                    <div className='col-12 col-lg-4 text-white'>
                        <div className='my-4 my-lg-0 text-center text-lg-left'>
                            <div className='text-regent-gray mb-1 fs-14'>
                                {t('footer.inTouch')}
                            </div>
                            <a href='mailto:hello@tokitus.com' className='text-white text-decoration-none fs-14'>
                                hello@tokitus.com
                            </a>
                        </div>
                    </div>
                    <div className='col-12 col-lg-4'>
                        <div className='mb-1-5-rem'>
                            <TrustPilotButton
                                text={t('common.seeOurReviewsOn')}
                                variant='light'
                                className='text-white text-center text-lg-right'
                            />
                        </div>
                        <div
                            className='d-flex align-items-end justify-content-center justify-content-lg-end social-icons'>
                            <a
                                target='_blank'
                                rel='noopener noreferrer'
                                href={t('socialMedia.facebookLink')}
                                className='facebook'
                            >
                                <img width='48'
                                     height='48'
                                     loading='lazy'
                                     src='/images/social-icons/ico-facebook.svg'
                                     alt='Facebook icon'
                                />
                            </a>
                            <a
                                target='_blank'
                                rel='noopener noreferrer'
                                href={t('socialMedia.instagramLink')}
                                className='instagram'>
                                <img
                                    width='48'
                                    height='48'
                                    loading='lazy'
                                    src='/images/social-icons/ico-instagram.svg'
                                    alt='Instagram icon'
                                />
                            </a>
                            <a
                                target='_blank'
                                rel='noopener noreferrer'
                                href={t('socialMedia.linkedinLink')}
                                className='linkedin'>
                                <img
                                    width='48'
                                    height='48'
                                    loading='lazy'
                                    src='/images/social-icons/ico-linkedin.svg'
                                    alt='Linkedin icon'
                                />

                            </a>
                            <a
                                target='_blank'
                                rel='noopener noreferrer'
                                href={t('socialMedia.youtubeLink')}
                                className='youtube'>
                                <img
                                    width='48'
                                    height='48'
                                    loading='lazy'
                                    src='/images/social-icons/ico-youtube.svg'
                                    alt='Youtube icon'
                                />
                            </a>
                        </div>
                    </div>
                </div>
                <hr className='mt-2-rem mb-1-5-rem bg-white divider-line'/>
                <div className='row'>
                    <div
                        className='col-12 order-1 order-lg-0 col-lg-6 d-flex align-items-center text-white justify-content-center justify-content-lg-start'>
                        <Link to='/' className='mr-3'>
                            <img width='140' height='33' loading='lazy' src='/images/logo-inverted.svg' alt='Tokitus'/>
                        </Link>
                        <div className='text-regent-gray fs-12'>©2020-2021 {t('footer.allRightsReserved')}.</div>
                    </div>
                    <div
                        className='col-10 offset-1 col-md-8 offset-md-2 col-lg-4 offset-lg-2 mb-1-5-rem mb-lg-0 order-0 order-lg-1 d-flex justify-content-between align-items-center'>
                        <a className='fs-12 text-regent-gray text-decoration-none' target='_blank'
                           rel='noopener noreferrer' href={t('footer.termsLink')}>{t('footer.terms')}</a>
                        <a className='fs-12 text-regent-gray text-decoration-none' target='_blank'
                           rel='noopener noreferrer' href={t('footer.personalDataLink')}>{t('footer.personalData')}</a>
                        <a className='fs-12 text-regent-gray text-decoration-none' target='_blank'
                           rel='noopener noreferrer' href={t('footer.cookiePolicyLink')}>{t('footer.cookiePolicy')}</a>
                    </div>
                </div>
            </div>
        </footer>
    );
};

export default Footer;
